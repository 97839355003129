import "../globals";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import { MainNavigation } from "./MainNavigation";
import { Footer } from "./Footer";
import background from "../images/background.jpg";

const titles = {
  fi: "Liukastumisvaroitus tekstiviestillä",
  sv: "Halkvarningstjänst",
  en: "Slipping warning service",
};

const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted;
};

export const Page = ({ children, page = "Etusivu", language = "fi" }) => {
  const isMounted = useIsMounted();

  return (
    <main
      style={{
        background: `var(--color-white) url(${background}) repeat-x`,
        padding:
          "var(--spacing-layout-m) var(--spacing-layout-2-xs) var(--spacing-layout-l)",
        display: isMounted ? "inherit" : "none", // Workaround for flash of unstyled hds-react content.
        justifyContent: "center",
      }}
    >
      <Helmet
        htmlAttributes={{
          lang: language,
        }}
      >
        <title>
          {page} :: {titles[language]}
        </title>
      </Helmet>

      <Container
        style={{
          backgroundColor: "var(--color-white)",
          boxShadow:
            "0 var(--spacing-4-xs) var(--spacing-2-xs) var(--color-black-40)",
          padding: 0,
        }}
      >
        <MainNavigation
          page={page}
          language={language}
          title={titles[language]}
        />

        <div
          id="content"
          style={{
            margin: "0 var(--spacing-layout-s)",
          }}
        >
          {children}
        </div>

        <Footer />
      </Container>
    </main>
  );
};
