import React from "react";

export const Content = ({
  children,
  as: Element = "p",
  style = {},
  ...props
}) => (
  <Element
    {...props}
    style={{
      fontFamily: "var(--font-default)",
      fontSize: "var(--fontsize-body-m)",
      lineHeight: "var(--lineheight-l)",
      ...style,
    }}
  >
    {children}
  </Element>
);
