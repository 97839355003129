import React from "react";
import { Content } from "./Content";

export const Footer = () => (
  <Content
    as="div"
    style={{
      marginTop: "var(--spacing-layout-2-xs)",
      padding: "var(--spacing-layout-2-xs) var(--spacing-layout-s)",
      borderTop: "1px solid var(--color-black-20)",
      fontSize: "var(--fontsize-body-s)",
    }}
  >
    © Liukkausvaroitus tekstiviestillä, palvelu jalankulkijoille 2021
  </Content>
);
