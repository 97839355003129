import React from "react";
import { Navigation } from "hds-react";

const links = {
  fi: [
    {
      label: "Etusivu",
      href: "/",
    },
    {
      label: "Tavoitteet",
      href: "/tavoitteet",
    },
    {
      label: "Periaatteet",
      href: "/periaatteet",
    },
    {
      label: "Varoitukset",
      href: "/varoitukset",
    },
    {
      label: "Kokemukset",
      href: "/kokemukset",
    },
    {
      label: "Vältä liukastumiset",
      href: "/valta-liukastumiset",
    },
    {
      label: "Anna palautetta",
      href: "/anna-palautetta",
    },
    {
      label: "Talvihoitovinkit",
      href: "/talvihoitovinkit",
    },
    {
      label: "Kehittäjille",
      href: "https://sva-konsultointi.github.io/liukastumisvaroitus-api-doc/",
    },
  ],
  sv: [
    {
      label: "Förstasidan",
      href: "/sv/",
    },
    {
      label: "Principer",
      href: "/sv/principer",
    },
    {
      label: "Minska halkrisken",
      href: "/sv/minska-halkrisken",
    },
    {
      label: "Ge feedback",
      href: "/sv/ge-feedback",
    },
    {
      label: "För applikationsutvecklare",
      href: "https://sva-konsultointi.github.io/liukastumisvaroitus-api-doc/",
    },
  ],
  en: [
    {
      label: "Home",
      href: "/en/",
    },
    {
      label: "Principles",
      href: "/en/principles",
    },
    {
      label: "Avoid slipping",
      href: "/en/avoid-slipping",
    },
    {
      label: "Contact",
      href: "/en/contact",
    },
    {
      label: "For developers",
      href: "https://sva-konsultointi.github.io/liukastumisvaroitus-api-doc/",
    },
  ],
};

const Label = ({ children }) => (
  <span
    style={{
      fontFamily: "var(--font-default)",
    }}
  >
    {children}
  </span>
);

const Title = ({ children }) => (
  <span
    style={{
      fontFamily: "var(--font-default)",
    }}
  >
    {children}
  </span>
);

export const MainNavigation = ({ page, title, language }) => (
  <Navigation
    title={<Title>{title}</Title>}
    menuToggleAriaLabel="menu"
    skipTo="#content"
    skipToContentLabel="Skip to content"
    titleUrl="/"
  >
    <Navigation.Row>
      {links[language].map(({ label, ...link }) => (
        <Navigation.Item
          key={label}
          label={<Label>{label}</Label>}
          active={label === page}
          {...link}
        />
      ))}
    </Navigation.Row>

    <Navigation.Actions>
      <Navigation.LanguageSelector label={language.toUpperCase()}>
        <Navigation.Item href="/" label="Suomeksi" />
        <Navigation.Item href="/sv/" label="På svenska" />
        <Navigation.Item href="/en/" label="In English" />
      </Navigation.LanguageSelector>
    </Navigation.Actions>
  </Navigation>
);
